import { Controller } from 'stimulus'

import EventUtil from 'scripts/utils/event'

export default class extends Controller {
  static targets = [ 'facetForm', 'form', 'orderMethodInput', 'resultsPerPageInput' ]

  launch() {
    this.formTarget.submit()
  }

  filterByFacet(event) {
    const facetInput = event.target
    let conditionToClone = (facetInput.type == 'checkbox' && facetInput.checked) ||
                           (facetInput.type == 'text' && facetInput.value != '')

    if (conditionToClone) {
      let cloneInput = document.createElement('input')

      cloneInput.type = 'hidden'
      cloneInput.dataset.id = facetInput.dataset.id
      cloneInput.name = facetInput.name
      cloneInput.value = facetInput.value

      this.formTarget.append(cloneInput)
    } else {
      this.formTarget.querySelector('[data-id=' + facetInput.dataset.id + ']').remove()
    }

    this.launch()
  }

  removeFilter(event) {
    event.preventDefault()

    const target = event.target.closest('a')
    let cloneInput = this.facetFormTarget.querySelector('[data-id=' + target.dataset.id + ']')

    switch (cloneInput.type) {
      case 'checkbox':
        cloneInput.checked = false
        break
      default:
        cloneInput.value = ''
    }

    EventUtil.trigger(cloneInput, 'change')
  }

  updateResultsPerPage(event) {
    this.resultsPerPageInputTarget.value = event.target.value
    this.launch()
  }

  toggleOrderMethod(event) {
    event.preventDefault()
    this.orderMethodInputTarget.value = this.orderMethodInputTarget.value == 'desc' ? 'asc' : 'desc'
    this.launch()
  }

  toggleFilters(event) {
    if (event) event.preventDefault()
    event.target.closest('.side-box-filter').classList.toggle('is-open')
  }
}
