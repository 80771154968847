import Rails from '@rails/ujs'

import EventUtil from 'scripts/utils/event'

class Confirm {
  constructor() {
    this.selectors = {
      callback: '.o-js-modal-confirm-callback',
      confirm: '[data-confirm]',
      modal: '#main-modal',
      modalSubmit: '.submit',
    }
  }

  init() {
    const allowAction = target => {
      let el = target

      if (!el.dataset.confirm) {
        el = el.closest(this.selectors.confirm)
        if (!el) return true
      }

      show(el)
      return false
    }

    const confirmed = el => {
      if (el.dataset.remote === 'true' || el.dataset.confirmForceClose === 'true') {
        document.querySelector(this.selectors.modal).modal.close()
      }

      el.removeAttribute('data-confirm')

      let a = el.cloneNode(true)
      a.classList.add(this.selectors.callback.replace('.', ''))
      document.body.appendChild(a)

      let modalConfirmCallback = document.querySelector(this.selectors.callback)
      modalConfirmCallback.addEventListener('click', Rails.handleMethod)
      modalConfirmCallback.click()
      modalConfirmCallback.remove()
    }

    const handleConfirm = e => {
      if (!allowAction(e.target)) Rails.stopEverything(e)
    }

    const show = (el) => {
      const modalMessage = el.dataset.confirm
      const modalTitle = el.dataset.confirmModalTitle
      const modalCancelBtn = el.dataset.confirmModalCancelButton
      const modalCloseBtn = el.dataset.confirmModalCloseButton
      const modalSubmitBtn = el.dataset.confirmModalSubmitButton

      const modalHtml = `
        <div class="modal modal--center">
          <div class="modal__content">
            <button class="modal__close">
            <span class="sr-only">${modalCloseBtn}</span>
              <i class="fal fa-times-circle" aria-hidden="true" data-micromodal-close></i>
            </button>
            <div class="modal__head">
              <p class="modal__title text-blue-dark">${modalTitle}</p>
            </div>
            <div class="modal__body">
              <p>${modalMessage}</p>
            </div>
            <div class="modal__foot">
              <div class="flex space-x-3">
                <button type="button" class='btn btn-sm btn-outline-gray-110 flex-1 cancel' data-micromodal-close>${modalCancelBtn}</button>
                <button type="button" class="btn btn-sm btn-yellow-brand flex-1 submit">${modalSubmitBtn}</button>
              </div>
            </div>
          </div>
        </div>`

      document.querySelector(this.selectors.modal).modal.set(modalHtml)
      EventUtil.addListeners(document.querySelector(this.selectors.modal).modal.element.querySelector(this.selectors.modalSubmit), 'click', e => confirmed(el))
    }

    Rails.delegate(document, this.selectors.confirm, 'click', handleConfirm)
  }
}

const instance = new Confirm()
Object.freeze(instance)

export default instance
