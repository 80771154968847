import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

import { useControllerMixin } from 'scripts/mixins/controller'

export default class extends Controller {
  connect() {
    useControllerMixin(this)
  }

  new(event) {
    event.preventDefault()

    const modalContentUrl = event.currentTarget.dataset.url

    if (this.hasController('cart')) {
      this.getController('cart').closeModal()
    }

    this.getController('modal').call(modalContentUrl, { dataType: 'html' })
  }

  create(event) {
    event.preventDefault()

    let self = this
    const form = event.currentTarget.closest('form')

    Rails.ajax({
      url: form.action,
      type: 'POST',
      data: new FormData(form),
      success: function(data) {
        self.getController('modal').set(data.documentElement.innerHTML)
      }
    })
  }

  cancel(event) {
    event.preventDefault()
  }
}
