import { Controller } from 'stimulus'

import ActionUtil from 'scripts/utils/action'
import DeviceUtil from 'scripts/utils/device'
import GlobalUtil from 'scripts/utils/global'

export default class extends Controller {
  static targets = [ 'link', 'menu' ]

  initialize() {
    this.selectors = {
      controller: '[data-controller="mega-menu"]',
      menu: '[data-target="mega-menu.menu"]',
      parent: '.has-children',
      trigger: '[data-target="mega-menu.link"]'
    }
  }

  activateMenu(event) {
    event.preventDefault()

    if (event.type === 'mouseenter' && DeviceUtil.getMediaType() === 'desktop') {
      const targetedMenu = this.getTargetedMenu(event.target)

      this.openMenu(targetedMenu)
      this.closeSiblingMenus(targetedMenu)
    }
  }

  mobileActivateMenu(event) {
    event.preventDefault()

    if (event.type === 'click' && DeviceUtil.getMediaType() === 'mobile') {
      const targetedMenu = this.getTargetedMenu(event.currentTarget)
      const icon = event.currentTarget.querySelector('.action__icon')

      if (targetedMenu.classList.contains('is-active')) {
        this.closeMenu(targetedMenu)
      } else {
        this.openMenu(targetedMenu)
      }

      this.closeSiblingMenus(targetedMenu)
    }
  }

  openMenu(menu) {
    const link = this.getMenuLink(menu)

    link.classList.add('is-active')
    menu.classList.add('is-active')
    ActionUtil.lockBodyScroll(true)

    if (menu.dataset.level == 2) this.setLevel2Format()
  }

  closeMenu(menu) {
    const link = this.getMenuLink(menu)

    link.classList.remove('is-active')
    menu.classList.remove('is-active')
    ActionUtil.lockBodyScroll(false)
  }

  closeSiblingMenus(activeMenu) {
    const activeParent = this.getItemParent(activeMenu)
    const levelParent = activeParent.closest(this.selectors.menu)
    const siblingMenus = Array.from(levelParent.querySelectorAll(this.selectors.menu)).filter(menu => menu !== activeMenu)

    siblingMenus.forEach(menu => this.closeMenu(menu))
  }

  getMenuLink(menu) {
    const parent = this.getItemParent(menu)

    return parent.querySelector(this.selectors.trigger + '[data-targeted-level="' + menu.dataset.level + '"]')
  }

  getTargetedMenu(trigger) {
    const targetedLevel = trigger.dataset.targetedLevel
    const parent = this.getItemParent(trigger)

    return parent.querySelector(this.selectors.menu + '[data-level="' + targetedLevel + '"]')
  }

  getItemParent(el) {
    return el.parentElement.closest(this.selectors.parent)
  }

  setLevel2Format() {
    const topParent = document.querySelector(this.selectors.controller)
    const level2Containers = topParent.querySelectorAll('[data-level="2"]')
    const level2MainMaxHeight = document.documentElement.clientHeight - topParent.offsetTop - topParent.offsetHeight - 40

    GlobalUtil.forEach(level2Containers, container => {
      if (DeviceUtil.getMediaType() === 'desktop') {
        container.style.top = topParent.offsetHeight + 'px'
        container.querySelector('.submenu-level2__main').style.maxHeight = level2MainMaxHeight + 'px'
      } else {
        container.style.top = ''
        container.querySelector('.submenu-level2__main').style.maxHeight = ''
      }
    })
  }
}
