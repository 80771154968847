import MicroModal from 'micromodal'
import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

import { useApplicationMixin } from 'scripts/mixins/application'
import ActionUtil from 'scripts/utils/action'

export default class extends Controller {
  static targets = [ 'container' ]

  connect() {
    useApplicationMixin(this)
    this.storeControllerInElement()

    MicroModal.init({
      disableScroll: true,
      disableFocus: true
    })
  }

  call(url, { type = 'GET', dataType = 'script' } = {}) {
    let self = this

    if (url) {
      Rails.ajax({
        url: url,
        type: type,
        dataType: dataType,
        success: function(data) {
          if (dataType == 'html') {
            self.set(data.documentElement.innerHTML)
          }
        }
      })
    }
  }

  set(content, { autoShow = true, id = '' } = {}) {
    this.containerTarget.innerHTML = content
    if (id) this.containerTarget.id = id
    if (autoShow) this.show()
  }

  show(callback) {
    ActionUtil.lockBodyScroll(true)

    MicroModal.show(this.element.id, {
      onClose: modal => {
        ActionUtil.lockBodyScroll(false)
        this.containerTarget.id = ''
        if (callback) callback()
      }
    })
  }

  close() {
    MicroModal.close(this.element.id)
  }
}
