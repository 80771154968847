import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.config = {
      toggleClass: 'is-open'
    }
  }

  open(event) {
    if (event) event.preventDefault()
    this.element.classList.add(this.config.toggleClass)
  }

  close(event) {
    if (event) event.preventDefault()
    this.element.classList.remove(this.config.toggleClass)
  }

  switch(event) {
    if (!this.element.classList.contains(this.config.toggleClass)) {
      event.preventDefault()
      this.open()
    }
  }
}
