// Full Tailwind default config : https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js

let config = {
  purge: [
    'app/**/*.html.erb',
    'app/helpers/**/*.rb',
    'app/inputs/**/*.rb',
    'app/javascript/**/*.js',
    'app/javascript/**/*.js.erb',
  ],
  theme: {
    borderWidth: {
      default: '1px',
      '0': '0',
      '2': '2px',
      '3': '3px',
      '4': '4px',
    },
    boxShadow: {
      'none': 'none',
      '6': '0 0 6px 0 rgba(0, 0, 0, 0.1)',
      '10': '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      '12': '0 0 12px 0 rgba(0, 0, 0, 0.1)',
      '14': '0 0 14px 0 rgba(0, 0, 0, 0.1)',
      '16': '0 0 16px 0 rgba(0, 0, 0, 0.1)',
    },
    colors: {
      black: '#000',
      blue: {
        'brand': '#2E55A2',
        'medium': '#0091FF',
        'dark': '#1B2D54',
      },
      cyan: {
        'medium': '#6EBDC1',
      },
      emerald: {
        'medium': '#68CD83',
      },
      gray: {
        'brand': '#5F5F5F',
        '10': '#F5F5F5',
        '20': '#ECECEB',
        '30': '#D9D9D9',
        '40': '#C5C5C5',
        '50': '#B2B2B1',
        '60': '#9C9C9C',
        '70': '#878786',
        '80': '#6F6F6E',
        '90': '#575756',
        '100': '#3C3C3B',
        '110': '#272727',
        '120': '#1D1D1B',
      },
      green: {
        'light': '#C9D5CF',
        'medium': '#5EB69A',
        'dark': '#1E5139',
      },
      indigo: {
        'medium': '#7A8DB4',
      },
      orange: {
        'lighter': '#F0B563',
        'light': '#EA7F57',
        'medium': '#E45723',
      },
      red: {
        'medium': '#E15857',
      },
      sand: {
        'lighter': '#FFFCEE',
        'light': '#FFE9B2',
        'medium': '#F2DCA5',
      },
      transparent: 'transparent',
      yellow: {
        'brand': '#F8EB67',
        'light': '#FCF7C2',
      },
      white: '#FFF',
    },
    filter: {
      'none': 'none',
      'grayscale': 'grayscale(100%)',
    },
    fontFamily: {
      'eurego': ['Eurego', 'Arial', 'sans-serif'],
      'poppins': ['Poppins', 'Arial', 'sans-serif'],
      'fa': '"Font Awesome 5 Pro"'
    },
    screens: {
      // Screen only
      'xl-only': { 'min': '1280px', 'max': '1439px' },
      'lg-only': { 'min': '1024px', 'max': '1279px' },
      'md-only': { 'min': '768px', 'max': '1023px' },
      'sm-only': { 'min': '480px', 'max': '767px' },
      // Screen minimum width
      'sm-more': { 'min': '480px' },
      'md-more': { 'min': '768px' },
      'lg-more': { 'min': '1024px' },
      'xl-more': { 'min': '1280px' },
      'default-more': { 'min': '1440px' },
      // Screen maximum width
      'xl': { 'max': '1439px' },
      'lg': { 'max': '1279px' },
      'md': { 'max': '1023px' },
      'sm': { 'max': '767px' },
      'xs': { 'max': '479px' },
    },
    extend: {
      backgroundPosition: {
        'right-center': 'right center',
      },
      borderRadius: {
        '8': '8px',
        '24': "24px",
      },
      flex: {
        '3': '3 3 0%',
      },
      fontSize: {
        '2xs': '.625em',
      },
      inset: {
        '1/2': '50%',
        'full': '100%',
      },
      opacity: {
        '5': '0.05',
        '10': '0.1',
        '90': '0.9',
      },
      spacing: {
        'inherit': 'inherit',
        '0\.5': '0.125rem',
        '14': '3.5rem',
        '18': '4.5rem',
        '28': '7rem',
        '72': '18rem',
        '84': '21rem',
        '88': '22rem',
        '96': '24rem',
      },
      transitionProperty: {
        'height': 'height',
        'filter': 'filter',
        'spacing': 'margin, padding',
      }
    },
  },
  variants: {
    backgroundColor: ['responsive', 'group-hover', 'hover', 'focus'],
    borderWidth: ['responsive', 'hover', 'focus', 'last'],
    display: ['responsive', 'group-hover'],
    filter: ['responsive', 'hover'],
    margin: ['first'],
    opacity: ['responsive', 'hover', 'focus', 'disabled'],
    padding: ['responsive', 'odd', 'even'],
    textColor: ['responsive', 'group-hover', 'hover', 'focus'],
  },
  corePlugins: {
    overscrollBehavior: false,
    backgroundAttachment: false,
    backgroundClip: false,
    backgroundImage: false,
    gradientColorStops: false,
    borderCollapse: false,
    divideWidth: false,
    divideColor: false,
    divideOpacity: false,
    divideStyle: false,
    float: false,
    clear: false,
    listStylePosition: false,
    fill: false,
    stroke: false,
    strokeWidth: false,
    tableLayout: false,
    fontVariantNumeric: false,
    letterSpacing: false,
    wordBreak: false,
    resize: false,
    userSelect: false,
    transitionTimingFunction: false,
    transitionDelay: false,
    animation: false,
  },
  plugins: [
    // --- Externals ---
    require('@tailwindcss/aspect-ratio'),
    // require('tailwindcss-blend-mode'), // Not installed/used yet (https://github.com/hacknug/tailwindcss-blend-mode)
    // require('tailwindcss-children'), // Not installed/used yet (https://github.com/benface/tailwindcss-children)
    // require('tailwindcss-custom-native'), // Not installed/used yet (https://github.com/SirNavith/tailwindcss-custom-native)
    require('tailwindcss-filters'),
    // require('tailwindcss-fluid') // Not installed/used yet (https://github.com/bradlc/tailwindcss-fluid)
    // require('tailwindcss-scrims') // Not installed/used yet (https://github.com/brettgullan/tailwindcss-scrims)
    // require('tailwindcss-truncate-multiline') // Not installed/used yet (https://github.com/jhta/tailwindcss-truncate-multiline)

    // --- Base ---
    require('./base/button'),
    require('./base/input'),

    // --- Utilities ---
    require('./utilities/flexbox'),
    require('./utilities/inset'),
    require('./utilities/placeholder'),
  ],
}

if (process.env.NODE_ENV == 'development') {
  config.plugins.push(
    require('tailwindcss-debug-screens')
  )
}

module.exports = config
