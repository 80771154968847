import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'stylesheets/tailwind/tailwind.config.js'

import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.tailwindConfig = resolveConfig(tailwindConfig)

    this.config = {
      duration: 6000,
      position: {
        x: 'right',
        y: 'top',
      },
      types: [
        {
          type: 'alert',
          backgroundColor: this.tailwindConfig.theme.colors.red.medium,
          icon: {
            className: 'fal fa-exclamation-triangle',
            tagName: 'i'
          }
        },
        {
          type: 'error',
          backgroundColor: this.tailwindConfig.theme.colors.red.medium,
          icon: {
            className: 'fal fa-exclamation-triangle',
            tagName: 'i'
          }
        },
        {
          type: 'info',
          backgroundColor: this.tailwindConfig.theme.colors.blue.brand,
          icon: {
            className: 'fal fa-info-circle',
            tagName: 'i'
          }
        },
        {
          type: 'notice',
          backgroundColor: this.tailwindConfig.theme.colors.blue.brand,
          icon: {
            className: 'fal fa-info-circle',
            tagName: 'i'
          }
        },
        {
          type: 'success',
          backgroundColor: this.tailwindConfig.theme.colors.green.medium,
          icon: {
            className: 'fal fa-check-circle',
            tagName: 'i'
          }
        },
        {
          type: 'warning',
          backgroundColor: this.tailwindConfig.theme.colors.orange.medium,
          icon: {
            className: 'fal fa-exclamation-circle',
            tagName: 'i'
          }
        }
      ]
    }

    this.notification = new Notyf(this.config)
  }

  connect() {
    this.open()
  }

  open() {
    this.notification.open({ type: this.data.get('type'), message: this.data.get('message') })
  }
}
