import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'item', 'showButton', 'hideButton' ]

  initialize() {
    this.min = parseInt(this.element.dataset.min)
    this.step = parseInt(this.element.dataset.step)
    this.currentIndex = this.min - 1
  }

  connect() {
    if (this.itemTargets.length > this.min) {
      this.showButtonTarget.classList.remove('hidden')

      this.itemTargets.forEach((item, index) => {
        if (index > this.min - 1) {
          item.classList.add('hidden')
        }
      })
    }
  }

  show() {
    this.itemTargets.forEach((item, index) => {
      // // => WIP step
      // if (this.step) {
      //   if (index <= this.currentIndex + this.step) {
      //     item.classList.remove('hidden')
      //   } else {
      //     item.classList.add('hidden')
      //   }
      // } else {
      item.classList.remove('hidden')
      // }
    })

    // // => WIP step
    // if (this.step) {
    //   this.currentIndex = this.currentIndex + this.step
    // } else {
    this.currentIndex = this.itemTargets.length - 1
    // }

    this.updateButtons()
  }

  hide() {
    this.itemTargets.forEach((item, index) => {
      // // => WIP step
      // if (this.step) {
      //   if (index >= this.currentIndex - this.step) {
      //     item.classList.add('hidden')
      //   } else {
      //     item.classList.remove('hidden')
      //   }
      // } else if {
      if (index > this.min - 1) {
        item.classList.add('hidden')
      }
    })

    // // => WIP step
    // if (this.step) {
    //   this.currentIndex = this.currentIndex - this.step
    // } else {
    this.currentIndex = this.min - 1
    // }

    this.updateButtons()
  }

  updateButtons() {
    if (this.currentIndex >= this.itemTargets.length - 1) {
      this.showButtonTarget.classList.add('hidden')
    } else {
      this.showButtonTarget.classList.remove('hidden')
    }

    if (this.currentIndex <= this.min - 1) {
      this.hideButtonTarget.classList.add('hidden')
    } else {
      this.hideButtonTarget.classList.remove('hidden')
    }
  }
}
