import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'stylesheets/tailwind/tailwind.config.js'

class DeviceUtil {
  getMediaQuery() {
    const twConfig = resolveConfig(tailwindConfig)
    const screens = twConfig.theme.screens

    if (window.innerWidth <= parseInt(screens.xs.max, 10)) {
      return 'xs'
    } else if (window.innerWidth <= parseInt(screens.sm.max, 10)) {
      return 'sm'
    } else if (window.innerWidth <= parseInt(screens.md.max, 10)) {
      return 'md'
    } else if (window.innerWidth <= parseInt(screens.lg.max, 10)) {
      return 'lg'
    } else if (window.innerWidth <= parseInt(screens.xl.max, 10)) {
      return 'xl'
    } else {
      return 'default'
    }
  }

  getMediaType() {
    const mediaQuery = this.getMediaQuery()

    if (['xs', 'sm', 'md'].includes(mediaQuery)) {
      return 'mobile'
    } else {
      return 'desktop'
    }
  }

  getMSBrowserVersion() {
    let ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ')
    const trident = ua.indexOf('Trident/')
    const edge = ua.indexOf('Edge/')

    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
    }

    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:')
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
    }

    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
    }

    // other browser
    return false
  }
}

const instance = new DeviceUtil()
Object.freeze(instance)

export default instance
