import Flatpickr from 'stimulus-flatpickr'
import 'flatpickr/dist/flatpickr.css'

// Locales
import { French } from 'flatpickr/dist/l10n/fr.js'

import GlobalUtil from 'scripts/utils/global'

// Override stimulus-flatpickr
export default class extends Flatpickr {
  static targets = [ 'valueDisplay' ]

  initialize() {
    this.locales = {
      fr: French
    }

    this.config = {
      locale: this.locale,
      dateFormat: 'Y-m-d',
      altInput: true,
      altFormat: 'm/d/Y',
      disableMobile: 'true',
    }

    if (this.locale == French) {
      this.config.altFormat = 'd/m/Y'
    }
  }

  ready(selectedDates, dateStr, instance) {
    this.setInputType(instance.input)
    this.displayInputValue(instance.input)
  }

  valueUpdate(selectedDates, dateStr, instance) {
    this.setInputType(instance.input)
    this.displayInputValue(instance.input)
  }

  setInputType(input) {
    if (input.dataset.type && input.type !== input.dataset.type) {
      input.type = input.dataset.type
    }
  }

  displayInputValue(input) {
    if (input.value && this.valueDisplayTargets.length) {
      this.valueDisplayTargets.forEach(display => {
        display.textContent = flatpickr.formatDate(flatpickr.parseDate(input.value, this.config.dateFormat), this.config.altFormat)
      })
    }
  }

  get locale() {
    return this.locales[GlobalUtil.getLocale()] || ''
  }
}
