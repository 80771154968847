import '../../../../assets/javascripts/ckeditor/content'

import { Controller } from 'stimulus'

import GlobalUtil from 'scripts/utils/global'

export default class extends Controller {
  connect() {
    this.wrapTables()
  }

  wrapTables() {
    GlobalUtil.forEach(this.element.querySelectorAll('table'), table => {
      const wrapper = document.createElement('div')

      wrapper.style.overflowX = 'auto'
      GlobalUtil.wrap(table, wrapper)
    })
  }
}
