import Glide, { Breakpoints, Controls } from '@glidejs/glide/dist/glide.modular.esm'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'slide' ]

  initialize() {
    this.selectors = {
      container: '[data-controller="' + this.element.dataset.controller +'"]',
    }
  }

  connect() {
    if (document.querySelector(this.selectors.container)) {
      new Glide(this.selectors.container, {
        type: 'slider',
        bound: true,
        perView: 3,
        gap: 20,
        breakpoints: {
          1023: {
            perView: 2
          },
          479: {
            perView: 1
          }
        }
      }).mount({ Breakpoints, Controls })
    }
  }
}
