// --- Rails scripts ---
require('turbolinks').start()
require('@rails/activestorage').start()

// --- Images ---
const images = require.context('images', true)
const imagePath = (name) => images(name, true)

// --- Frontend styles ---

import 'stylesheets/frontend/base'
import 'stylesheets/frontend/components'
import 'stylesheets/frontend/utilities'

// --- Frontend scripts ---
// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import consumer from '../channels/consumer'

import Rails from '@rails/ujs'
import Confirm from 'scripts/frontend/tools/confirm'
import Stripe from 'scripts/frontend/tools/stripe.js.erb'

const application = Application.start()
const context = require.context('scripts/frontend/controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

Confirm.init() // Confirm init always before Rails start
Rails.start()
Stripe.override()
