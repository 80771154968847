import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input' ]

  toggleVisibility(event) {
    this.element.classList.toggle('is-visible')
    this.inputTarget.type = this.element.classList.contains('is-visible') ? 'text' : 'password'
  }
}
