import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

import { useApplicationMixin } from 'scripts/mixins/application'
import ActionUtil from 'scripts/utils/action'

export default class extends Controller {
  static targets = [ 'body' ]

  initialize() {
    this.config = {
      openedClass: 'is-open',
    }

    this.selectors = {
      counters: {
        cart: '#cart-item-count',
        cartHeader: '#cart-header-item-count',
      }
    }

    this.scrollPosition = 0
  }

  connect() {
    useApplicationMixin(this)
    this.storeControllerInElement()
  }

  toggleModal(event) {
    if (event) event.preventDefault()
    this.element.classList.contains(this.config.openedClass) ? this.closeModal() : this.openModal()
  }

  openModal(event) {
    if (event) event.preventDefault()
    this.element.classList.add(this.config.openedClass)
    ActionUtil.lockBodyScroll(true)
  }

  closeModal(event) {
    if (event) event.preventDefault()
    this.element.classList.remove(this.config.openedClass)
    ActionUtil.lockBodyScroll(false)
  }

  refreshModal() {
    const element = this.element

    Rails.ajax({
      url: this.element.dataset.url,
      type: 'GET',
      dataType: 'html',
      success: function (data) {
        element.innerHTML = data.documentElement.innerHTML
      }
    })
  }

  updateCounters(value) {
    Object.values(this.selectors.counters).forEach(counter => {
      const counterElement = document.querySelector(counter)
      if (counterElement) counterElement.textContent = value
    })
  }

  saveScrollPosition() {
    if (this.hasBodyTarget) {
      this.scrollPosition = this.bodyTarget.scrollTop
    }
  }

  restoreScrollPosition() {
    if (this.hasBodyTarget) {
      this.bodyTarget.scrollTop = this.scrollPosition
    }
  }
}
