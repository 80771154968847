import { Controller } from 'stimulus'

import DeviceUtil from 'scripts/utils/device'
import EventUtil from 'scripts/utils/event'

export default class extends Controller {
  static targets = [ 'arrowLeft', 'arrowRight', 'event', 'eventBar' ]

  initialize() {
    this.config = {
      event: {
        offsetMargin: 0
      }
    }

    this.setOffsetMargin()
    EventUtil.addListeners(window, 'resize', e => this.setOffsetMargin())
  }

  connect() {
    if (!this.eventTargets.find(event => event.classList.contains('is-active'))) {
      this.activate(this.eventTargets[0])
    }
  }

  activate(itemSelected) {
    const itemSelectedIndex = this.eventTargets.indexOf(itemSelected)
    const prevItems = this.eventTargets.slice(0, itemSelectedIndex)
    const nextItems = itemSelectedIndex < this.eventTargets.length-1 ? this.eventTargets.slice(itemSelectedIndex+1, this.eventTargets.length) : []

    itemSelected.classList.remove('is-past')
    itemSelected.classList.add('is-active')
    prevItems.forEach(item => {
      item.classList.remove('is-active')
      item.classList.add('is-past')
    })
    nextItems.forEach(item => item.classList.remove('is-active', 'is-past'))

    this.eventBarTarget.style.left = -(itemSelected.offsetLeft - this.config.event.offsetMargin) + 'px'
    this.arrowLeftTarget.classList.toggle('hidden', itemSelectedIndex == 0)
    this.arrowRightTarget.classList.toggle('hidden', itemSelectedIndex == this.eventTargets.length-1)
  }

  move(dir) {
    const itemActive = this.eventTargets.find(event => event.classList.contains('is-active'))
    const itemActiveIndex = this.eventTargets.indexOf(itemActive)
    const itemFutureIndex = itemActiveIndex + dir

    if (itemFutureIndex >= 0 && itemFutureIndex < this.eventTargets.length && this.eventTargets[itemFutureIndex]) {
      this.activate(this.eventTargets[itemFutureIndex])
    }
  }

  next(event) {
    event.preventDefault()
    this.move(1)
  }

  prev(event) {
    event.preventDefault()
    this.move(-1)
  }

  select(event) {
    event.preventDefault()
    this.activate(event.target.parentElement)
  }

  setOffsetMargin() {
    switch (DeviceUtil.getMediaQuery()) {
      case 'sm':
        this.config.event.offsetMargin = 70
        break
      case 'xs':
        this.config.event.offsetMargin = 75
        break
      default:
        this.config.event.offsetMargin = 128
    }
  }
}
