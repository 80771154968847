// Set jQuery on global variable
global.$ = $

// OVERRIDE : Solidus Stripe import

require('imports-loader?$=>global.$!/mnt/vol-elegant-lovelace/prod/shared/bundle/ruby/2.6.0/gems/solidus_stripe-4.2.0/app/assets/javascripts/spree/frontend/solidus_stripe/stripe-payment')

require('imports-loader?$=>global.$!/mnt/vol-elegant-lovelace/prod/shared/bundle/ruby/2.6.0/gems/solidus_stripe-4.2.0/app/assets/javascripts/spree/frontend/solidus_stripe/stripe-elements')

require('imports-loader?$=>global.$!/mnt/vol-elegant-lovelace/prod/shared/bundle/ruby/2.6.0/gems/solidus_stripe-4.2.0/app/assets/javascripts/spree/frontend/solidus_stripe/stripe-payment-intents')

require('imports-loader?$=>global.$!/mnt/vol-elegant-lovelace/prod/shared/bundle/ruby/2.6.0/gems/solidus_stripe-4.2.0/app/assets/javascripts/spree/frontend/solidus_stripe/stripe-cart-page-checkout')

require('imports-loader?$=>global.$!/mnt/vol-elegant-lovelace/prod/shared/bundle/ruby/2.6.0/gems/solidus_stripe-4.2.0/app/assets/javascripts/spree/frontend/solidus_stripe/stripe-payment-request-button-shared')

require('imports-loader?$=>global.$!/mnt/vol-elegant-lovelace/prod/shared/bundle/ruby/2.6.0/gems/solidus_stripe-4.2.0/app/assets/javascripts/spree/frontend/solidus_stripe/stripe-init')


import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'stylesheets/tailwind/tailwind.config.js'

import GlobalUtil from 'scripts/utils/global'

import Loader from 'scripts/frontend/tools/loader'

class Stripe {
  constructor() {
    this.tailwindConfig = resolveConfig(tailwindConfig)
  }

  override() {
    this.overridePayment()
    this.overrideElements()
    this.overridePaymentIntents()
  }

  overrideElements() {
    let self = this
    let instance = SolidusStripe.Elements.prototype

    instance.baseStyle = function() {
      return {
        base: {
          iconColor: self.tailwindConfig.theme.colors.gray['110'],
          color: self.tailwindConfig.theme.colors.gray['110'],
          fontWeight: 400,
          fontFamily: self.tailwindConfig.theme.fontFamily.poppins.join(', '),
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          lineHeight: '1.5',
          '::placeholder': {
            color: self.tailwindConfig.theme.colors.gray['60'],
          },
        },
        invalid: {
          iconColor: self.tailwindConfig.theme.colors.red.medium,
          color: self.tailwindConfig.theme.colors.red.medium,
        }
      }
    }

    instance.showError = function(error) {
      var message = error.message || error

      Loader.hide()
      this.errorElement.text(message).show()
      this.submitButton.removeAttr('disabled').removeClass('disabled')
    }
  }

  overridePayment() {
    let instance = SolidusStripe.Payment.prototype

    instance.elementsBaseOptions = function() {
      return {
        locale: GlobalUtil.getLocale()
      }
    }
  }

  overridePaymentIntents() {
    let self = this
    let instance = SolidusStripe.PaymentIntents.prototype

    instance.onIntentsPayment = function(payment) {
      if (payment.error) {
        this.showError(payment.error.message)
      } else {
        var that = this

        Loader.show()

        this.elementsTokenHandler(payment.paymentMethod)
        fetch('/stripe/create_intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            form_data: this.form.serialize(),
            spree_payment_method_id: this.config.id,
            stripe_payment_method_id: payment.paymentMethod.id,
            authenticity_token: this.authToken
          })
        }).then(function(response) {
          response.json().then(function(json) {
            that.handleServerResponse(json, payment)
          })
        })
      }
    }
  }
}

const instance = new Stripe()
Object.freeze(instance)

export default instance
