import Glide, { Autoplay, Breakpoints, Controls } from '@glidejs/glide/dist/glide.modular.esm'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'slide' ]

  initialize() {
    this.selectors = {
      container: '[data-controller="' + this.element.dataset.controller +'"]',
    }
  }

  connect() {
    if (document.querySelector(this.selectors.container)) {
      new Glide(this.selectors.container, {
        type: 'slider',
        bound: true,
        perView: this.slideTargets.length > 6 ? 6 : this.slideTargets.length,
        autoplay: 4000,
        animationDuration: 1000,
        gap: 30,
        breakpoints: {
          1023: {
            perView: this.slideTargets.length > 3 ? 3 : this.slideTargets.length
          },
          767: {
            perView: this.slideTargets.length > 1 ? 1 : this.slideTargets.length
          }
        }
      }).mount({ Autoplay, Breakpoints, Controls })
    }
  }
}
