import { Controller } from 'stimulus'

import ActionUtil from 'scripts/utils/action'

export default class extends Controller {
  static targets = [ 'menu', 'menuOpener', 'subMenu', 'subMenuOpener', 'navItem', 'overlay' ]

  initialize() {
    this.config = {
      menuOpenedClass: 'has-menu-opened',
      stickyClass: 'is-sticky',
    }
  }

  connect() {
    this.lastScrollY = 0
    this.stickyDelta = 0
    this.setStickyState()
  }

  toggleMenu(event) {
    if (event) event.preventDefault()
    this.menuTarget.classList.toggle('is-active')
    this.element.classList.toggle(this.config.menuOpenedClass, this.menuTarget.classList.contains('is-active'))
    this.menuOpenerTarget.classList.toggle('is-active', this.menuTarget.classList.contains('is-active'))
    ActionUtil.lockBodyScroll(this.menuTarget.classList.contains('is-active'))
  }

  toggleSubMenu(event) {
    if (event) event.preventDefault()
    this.subMenuTarget.classList.contains('is-active') ? this.closeSubMenu() : this.openSubMenu()
  }

  openSubMenu(event) {
    if (event) event.preventDefault()
    this.subMenuTarget.classList.add('is-active')
    this.overlayTarget.classList.add('is-active')
    this.subMenuOpenerTarget.parentElement.classList.add('is-active')
    this.navItemTargets.forEach(item => {
      if (item !== this.subMenuOpenerTarget.parentElement) item.classList.add('is-overlayed')
    })
  }

  closeSubMenu(event) {
    if (event) event.preventDefault()
    this.subMenuTarget.classList.remove('is-active')
    this.overlayTarget.classList.remove('is-active')
    this.subMenuOpenerTarget.parentElement.classList.remove('is-active')
    this.navItemTargets.forEach(item => {
      if (item !== this.subMenuOpenerTarget.parentElement) item.classList.remove('is-overlayed')
    })
  }

  setStickyState() {
    if (Math.abs(this.lastScrollY - window.scrollY) <= this.stickyDelta) return
    this.element.classList.toggle(this.config.stickyClass, window.scrollY > this.stickyDelta)
    this.lastScrollY = window.scrollY
  }
}
