import DeviceUtil from './device'

class FontLoaderUtil {
  preload(fontsList) {
    const fontType = DeviceUtil.getMSBrowserVersion() && DeviceUtil.getMSBrowserVersion() < 14 ? 'woff' : 'woff2'
    const fontsToLoad = fontsList.map(font => ({ href: `${font}.${fontType}`, type: `font/${fontType}` }))
    const fonts = require.context('../../', true, /\.(eot|ttf|woff|woff2|otf)$/i)
    const fontPath = (name) => fonts(name, true)
    const fontsFragment = document.createDocumentFragment()

    fontsToLoad.forEach(font => {
      let fontLink = document.createElement('link')

      fontLink.rel = 'preload'
      fontLink.type = font.type
      fontLink.href = fontPath(font.href)
      fontLink.as = 'font'
      fontLink.crossOrigin = 'anonymous'

      fontsFragment.appendChild(fontLink)
    })

    document.head.appendChild(fontsFragment)
  }
}

const instance = new FontLoaderUtil()
Object.freeze(instance)

export default instance
