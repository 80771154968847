const plugin = require('tailwindcss/plugin')

module.exports = plugin(function({ addUtilities, variants }) {
  const newUtility = {
    '.placeholder-italic::placeholder': {
      fontStyle: 'italic',
    },
    '.placeholder-normal::placeholder': {
      fontStyle: 'normal',
    },
  }

  addUtilities(newUtility, variants('placeholderColor'))
})
