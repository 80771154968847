export class ApplicationMixin {
  constructor(controller) {
    /* ----- ASSIGN GETTERS ----- */
    Object.defineProperty(controller, 'formattedIdentifier', { // snake-case identifier to camelCase identifier
      get: () => {
        return controller.identifier
          .split(/[-_]/)
          .map((w) => w.replace(/./, (m) => m.toUpperCase()))
          .join('')
          .replace(/^\w/, (c) => c.toLowerCase())
      },
    })

    /* ----- ASSIGN FUNCTIONS ----- */
    Object.assign(controller, {
      storeControllerInElement: this.storeControllerInElement,
    })
  }

  /* ----- STORE STIMULUS CONTROLLER IN HTML ELEMENT ----- */
  storeControllerInElement() {
    this.element[this.formattedIdentifier] = this
  }
}

export const useApplicationMixin = (controller) => {
  return new ApplicationMixin(controller)
}
