import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'container', 'link' ]

  connect() {
    if (window.location.hash) {
      const target = document.querySelector(window.location.hash.trim())

      this.displayContainer(target)
    }

    if (this.containerTargets.length && !this.containerTargets.filter(container => container.classList.contains('is-active')).length ) {
      this.displayContainer(this.containerTargets[0])
    }
  }

  select(event) {
    event.preventDefault()

    const targetId = event.currentTarget.dataset.dest || event.currentTarget.getAttribute('href')
    const target = document.querySelector(targetId)

    if (target) this.displayContainer(target)
  }

  displayContainer(selectedContainer) {
    const selectedLink = this.linkTargets.find(link => link.getAttribute('href') === '#' + selectedContainer.id)

    this.containerTargets.forEach(container => container.classList.remove('is-active'))
    selectedContainer.classList.add('is-active')

    this.linkTargets.forEach(link => {
      link.classList.remove('is-active')
      link.setAttribute('aria-current', false)
    })

    if (selectedLink) {
      selectedLink.classList.add('is-active')
      selectedLink.setAttribute('aria-current', true)
    }
  }
}
