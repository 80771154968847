import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

import { useControllerMixin } from 'scripts/mixins/controller'

export default class extends Controller {
  initialize() {
    this.selectors = {
      quantityInput: '.quantity-input',
      reservationProduct: '.reservation-product',
      reservationProductTotalPrice: '.reservation-product-total-price'
    }
  }

  connect() {
    useControllerMixin(this)
  }

  new(event) {
    event.preventDefault()

    const productQuantity = event.currentTarget.closest('form').querySelector(this.selectors.quantityInput).value
    const modalContentUrl = event.currentTarget.dataset.url + '?quantity=' + productQuantity

    if (this.hasController('cart')) {
      this.getController('cart').closeModal()
    }

    this.getController('modal').call(modalContentUrl, { dataType: 'html' })
  }

  create(event) {
    event.preventDefault()

    let self = this
    const form = event.currentTarget.closest('form')

    Rails.ajax({
      url: form.action,
      type: 'POST',
      data: new FormData(form),
      success: function(data) {
        self.getController('modal').set(data.documentElement.innerHTML)
        self.getController('reservations--modal').refresh()
      }
    })
  }

  increaseQuantity(event) {
    event.preventDefault()

    const parent = event.currentTarget.parentElement
    const label = parent.querySelector(this.selectors.quantityLabel)
    const input = parent.querySelector(this.selectors.quantityInput)
    let newValue = parseInt(input.value) + 1

    if (event.currentTarget.dataset.divisiblePackaging == 'false' ) {
      newValue = parseInt(input.value) + parseInt(event.currentTarget.dataset.packagingQuantity)
    }

    input.value = newValue
    if (label) label.innerHTML = newValue
    this.updateProductFinalPrice(event)
  }

  decreaseQuantity(event) {
    event.preventDefault()

    const parent = event.currentTarget.parentElement
    const label = parent.querySelector(this.selectors.quantityLabel)
    const input = parent.querySelector(this.selectors.quantityInput)
    const minValue = input.dataset.minValue
    let newValue = parseInt(input.value) - 1

    if (event.currentTarget.dataset.divisiblePackaging == 'false' ) {
      newValue = parseInt(input.value) - parseInt(event.currentTarget.dataset.packagingQuantity)
    }

    if (newValue < minValue) {
      newValue = minValue
    }

    input.value = newValue
    if (label) label.innerHTML = newValue
    this.updateProductFinalPrice(event)
  }

  updateProductFinalPrice(event) {
    const form = event.currentTarget.closest('form')
    const reservationProduct = event.currentTarget.closest(this.selectors.reservationProduct)
    const productTotalPrice = reservationProduct.querySelector(this.selectors.reservationProductTotalPrice)

    Rails.ajax({
      url: reservationProduct.dataset.url,
      type: 'PATCH',
      datatype: 'json',
      data: new FormData(form),
      success: function(data) {
        productTotalPrice.innerHTML = data.display_item_total
      }
    })
  }

  cancel(event) {
    event.preventDefault()
  }
}
