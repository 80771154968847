class ActionUtil {
  lockBodyScroll(lock) {
    document.body.classList.toggle('has-scroll-disabled', lock)
  }

  scrollTo(top, { duration = 1000, includeHeader = true, extraMargin = 40 } = {}) {
    if (includeHeader) top -= document.querySelector('.page-header').offsetHeight

    const scrollTop = top - extraMargin

    if (duration === 0) {
      window.scrollTo(0, scrollTop)
    } else {
      $('html, body').animate({ scrollTop: scrollTop }, duration)
    }
  }
}

const instance = new ActionUtil()
Object.freeze(instance)

export default instance
