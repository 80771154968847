import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  updateInternalNumber(event) {
    const el = event.currentTarget
    const url = el.dataset.url
    const data = 'order[internal_number]=' + el.value

    Rails.ajax({
      url: url,
      type: 'PATCH',
      data: data,
      dataType: 'json',
      success: function(data) {
        if(data.error_message) {
          if (el.parentNode.querySelector('.form-message')) {
            el.parentNode.querySelector('.form-message').remove()
          }

          const error = document.createElement('p')
          error.classList.add('form-message')
          error.innerHTML = data.error_message
          el.parentNode.append(error)

          el.classList.add('is-invalid')
          el.closest('.form-group').classList.add('is-invalid')
        } else {
          window.location.href = el.dataset.returnUrl
        }
      }
    })
  }

  submitFilters(event) {
    event.currentTarget.closest('form').submit()
  }
}
