class Loader {
  constructor() {
    this.config = {
      activeClass: 'is-active',
      fullpageClass: 'is-fullpage',
    }

    this.selectors = {
      pageTypeId: '#page-loader'
    }
  }

  show (selector = document.querySelector(this.selectors.pageTypeId), fullpage = false) {
    selector.classList.add(this.config.activeClass)
    if (fullpage) selector.classList.add(this.config.fullpageClass)
  }

  hide (selector = document.querySelector(this.selectors.pageTypeId)) {
    selector.classList.remove(this.config.activeClass, this.config.fullpageClass)
  }
}

const instance = new Loader()
Object.freeze(instance)

export default instance
