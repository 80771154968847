import { Controller } from 'stimulus'

import ActionUtil from 'scripts/utils/action'
import FontLoaderUtil from 'scripts/utils/font_loader'
import GlobalUtil from 'scripts/utils/global'

export default class extends Controller {
  static targets = [ 'cart', 'modal', 'reservationModal' ]

  initialize() {
    this.config = {
      fonts: [
        './fonts/Eurego/Eurego-Bold',
        './fonts/Eurego/Eurego-BoldItalic',
        './fonts/Eurego/Eurego-Regular',
        './fonts/Eurego/Eurego-RegularItalic',
        './fonts/Eurego/Eurego-ExtraBold',
        './fonts/Eurego/Eurego-ExtraBoldItalic',
        './fonts/Eurego/Eurego-Light',
        './fonts/Eurego/Eurego-Medium',
        './fonts/Eurego/Eurego-MediumItalic',
        './fonts/Eurego/Eurego-SemiBold',
        './fonts/Eurego/Eurego-SemiBoldItalic',
        './fonts/Poppins/Poppins-Bold',
        './fonts/Poppins/Poppins-BoldItalic',
        './fonts/Poppins/Poppins-Regular',
        './fonts/Poppins/Poppins-RegularItalic',
        './fonts/Poppins/Poppins-ExtraBold',
        './fonts/Poppins/Poppins-ExtraBoldItalic',
        './fonts/Poppins/Poppins-Medium',
        './fonts/Poppins/Poppins-MediumItalic',
        './fonts/Poppins/Poppins-SemiBold',
        './fonts/Poppins/Poppins-SemiBoldItalic',
        './vendors/fontawesome-pro/webfonts/fa-brands-400',
        './vendors/fontawesome-pro/webfonts/fa-light-300',
        './vendors/fontawesome-pro/webfonts/fa-regular-400',
        './vendors/fontawesome-pro/webfonts/fa-solid-900',
      ]
    }

    this.selectors = {
      focusable: 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    }
  }

  connect() {
    this.preloadFonts()
  }

  displayCart(event) {
    event.preventDefault()
    this.cartTarget.cart.openModal()
  }

  displayReservationModal(event) {
    event.preventDefault()
    this.reservationModalTarget.productsReservationModal.open()
  }

  launchModal(event) {
    event.preventDefault()

    const modalContentUrl = event.target.dataset.href || event.target.href

    this.modalTarget.modal.call(modalContentUrl)
  }

  preloadFonts() {
    FontLoaderUtil.preload(this.config.fonts)
  }

  scrollToTarget(event) {
    event.preventDefault()

    const duration = event.currentTarget.dataset.duration
    const targetId = event.currentTarget.dataset.dest || event.currentTarget.getAttribute('href')
    const target = document.querySelector(targetId)

    if (target) {
      const firstFocusableItem = target.querySelector(this.selectors.focusable)
      const targetPos = GlobalUtil.viewportOffset(target).top

      duration ? ActionUtil.scrollTo(targetPos, { duration: duration }) : ActionUtil.scrollTo(targetPos)
      if (firstFocusableItem) firstFocusableItem.focus()
    }
  }

  scrollToTop(event) {
    event.preventDefault()

    const firstFocusableItem = document.querySelector(this.selectors.focusable)

    ActionUtil.scrollTo(0)
    if (firstFocusableItem) firstFocusableItem.focus()
  }
}
