import GlobalUtil from 'scripts/utils/global'

class EventUtil {
  // Add multiple listeners on multiple elements
  addListeners(elements = [], events = '', callback, { anim = false, options = false } = {}) {
    if (!NodeList.prototype.isPrototypeOf(elements) && !Array.isArray(elements)) elements = [elements]

    GlobalUtil.forEach(elements, (el, index) => {
      if (el) {
        events.split(' ').forEach(evt => {
          let ticking = false

          el.addEventListener(evt, e => {
            if (anim) {
              // Animation
              if (!ticking) {
                window.requestAnimationFrame(() => {
                  callback(e, el, index)
                  ticking = false
                })
              }

              ticking = true
            } else {
              // Not animation
              callback(e, el, index)
            }
          }, options)
        })
      }
    })
  }

  // Detect event outside elements
  addOutsideListeners(elements = [], events = '', callback, { parent = document, anim = false, options = false } = {}) {
    if (!NodeList.prototype.isPrototypeOf(elements) && !Array.isArray(elements)) elements = [elements]

    this.addListeners(parent, events, e => {
      const untargetedElements = elements.filter(el => el !== e.target && !el.contains(e.target) && GlobalUtil.isVisible(el))
      callback(e, untargetedElements)
    }, { anim: anim, options: options })
  }

  // Add listeners with event delegation
  delegateListeners(events = '', selectors = '', callback, { parent = document, anim = false, options = false, reverse = false } = {}) {
    events.split(' ').forEach(evt => {
      let ticking = false

      parent.addEventListener(evt, e => {
        let target

        for (let selector of selectors.split(',')) {
          target = e.target.matches(selector) ? e.target : e.target.closest(selector)
          if (target) break
        }

        if (reverse && !target) {
          callback(e, target)
        } else if (target) {
          if (anim) {
            // Animation
            if (!ticking) {
              window.requestAnimationFrame(() => {
                callback(e, target)
                ticking = false
              })
            }

            ticking = true
          } else {
            // Not animation
            callback(e, target)
          }
        }
      }, options)
    })
  }

  // Detect event outside elements with delegation
  delegateOutsideListeners(events = '', selectors = '', callback, { parent = document, anim = false, options = false } = {}) {
    this.delegateListeners(events, selectors, (e, target) => {
      if (GlobalUtil.isBlank(target)) callback(e)
    }, { parent: parent, anim: anim, options: options, reverse: true })
  }

  // Remove listeners
  removeListeners(elements = [], events = '', callback, { options = false } = {}) {
    if (!NodeList.prototype.isPrototypeOf(elements) && !Array.isArray(elements)) elements = [elements]

    GlobalUtil.forEach(elements, (el, index) => {
      if (el) {
        events.split(' ').forEach(evt => el.removeEventListener(evt, e => callback(e, el, index), options))
      }
    })
  }

  // Trigger event
  trigger(elements = [], event = '', { bubbles = true, cancelable = true, detail = {} } = {}) {
    let evt

    if (!NodeList.prototype.isPrototypeOf(elements) && !Array.isArray(elements)) elements = [elements]

    if (document.createEventObject) {
      evt = document.createEventObject()
      GlobalUtil.forEach(elements, el => el.fireEvent('on' + event, evt))
    } else {
      evt = document.createEvent('HTMLEvents')
      evt.initEvent(event, bubbles, cancelable, detail)

      GlobalUtil.forEach(elements, el => el.dispatchEvent(evt))
    }
  }

  triggerCustom(elements = [], event = '', { bubbles = true, cancelable = true, detail = {} } = {}) {
    let evt

    if (!NodeList.prototype.isPrototypeOf(elements) && !Array.isArray(elements)) elements = [elements]

    if (window.CustomEvent && typeof window.CustomEvent === 'function') {
      evt = new CustomEvent(event, { bubbles: bubbles, cancelable: cancelable, detail: detail })
    } else {
      evt = document.createEvent('CustomEvent')
      evt.initCustomEvent(event, bubbles, cancelable, detail)
    }

    GlobalUtil.forEach(elements, el => el.dispatchEvent(evt))
  }
}

const instance = new EventUtil()
Object.freeze(instance)

export default instance
