import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.selectors = {
      favoriteButton: '.favorite-button',
      favoriteGroup: '.favorite-group'
    }
  }

  destroyFavorite(event) {
    const self = this
    const element = event.currentTarget

    Rails.ajax({
      url: event.currentTarget.dataset.url,
      type: 'POST',
      success: function(data) {
        element.closest(self.selectors.favoriteGroup).parentNode.insertBefore(data.querySelector(self.selectors.favoriteGroup), element.closest(self.selectors.favoriteGroup))
        element.closest(self.selectors.favoriteGroup).remove()
      }
    })
  }
}
