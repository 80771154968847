const plugin = require('tailwindcss/plugin')

module.exports = plugin(function({ addBase }) {
  const newBase = {
    'input': {
      appearance: 'none',
      backgroundColor: 'transparent',
      borderRadius: '0',
    },
    'input[type="submit"]': {
      cursor: 'pointer',
    },
    'select': {
      appearance: 'none',
      backgroundColor: 'transparent',
      borderRadius: '0',
      cursor: 'pointer',
    },
    'textarea': {
      appearance: 'none',
      backgroundColor: 'transparent',
      borderRadius: '0',
      resize: 'vertical',
    },
  }

  addBase(newBase)
})
