import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

import { useApplicationMixin } from 'scripts/mixins/application'
import ActionUtil from 'scripts/utils/action'

export default class extends Controller {
  initialize() {
    this.config = {
      openedClass: 'is-open',
    }

    this.selectors = {
      counters: {
        mobileButton: '#mobile-button-reservations-item-count',
        reservationsHead: '#reservations-head-item-count',
        reservationsOpener: '#reservations-opener-item-count',
      }
    }
  }

  connect() {
    useApplicationMixin(this)
    this.storeControllerInElement()
  }

  toggle(event) {
    if (event) event.preventDefault()
    this.element.classList.contains(this.config.openedClass) ? this.close() : this.open()
  }

  open(event) {
    if (event) event.preventDefault()
    this.element.classList.add(this.config.openedClass)
    ActionUtil.lockBodyScroll(true)
  }

  close(event) {
    if (event) event.preventDefault()
    this.element.classList.remove(this.config.openedClass)
    ActionUtil.lockBodyScroll(false)
  }

  refresh() {
    let self = this

    Rails.ajax({
      url: this.element.dataset.url,
      type: 'GET',
      dataType: 'html',
      success: function (data) {
        self.element.parentNode.insertBefore(data.documentElement, self.element)
        self.element.remove()
      }
    })
  }

  updateCounters(value) {
    Object.values(this.selectors.counters).forEach(counter => {
      const counterElement = document.querySelector(counter)
      if (counterElement) counterElement.textContent = value
    })
  }
}
