import { Controller } from 'stimulus'

import ActionUtil from 'scripts/utils/action'
import GlobalUtil from 'scripts/utils/global'

export default class extends Controller {
  static targets = [ 'card', 'pointer' ]

  initialize() {
    this.config = {
      scroll: {
        duration: 800
      }
    }

    this.selectors = {
      focusable: 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    }
  }

  scrollToAgency(event) {
    event.preventDefault()

    const targetId = event.currentTarget.dataset.dest || event.currentTarget.getAttribute('href')
    const target = document.querySelector(targetId)

    this.cardTargets.forEach(card => card.classList.remove('is-active'))

    if (target) {
      const firstFocusableItem = target.querySelector(this.selectors.focusable)
      const targetPos = GlobalUtil.viewportOffset(target).top

      target.classList.add('is-active')
      ActionUtil.scrollTo(targetPos, { duration: this.config.scroll.duration })
      if (firstFocusableItem) firstFocusableItem.focus()
    }
  }
}
