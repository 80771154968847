import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

import { useApplicationMixin } from 'scripts/mixins/application'
import { useControllerMixin } from 'scripts/mixins/controller'

export default class extends Controller {
  initialize() {
    this.selectors = {
      reservationItemDestruction: '.reservation-item-destruction',
      quantityInput: '.quantity-input',
      quantityLabel: '.quantity-label',
    }
  }

  connect() {
    useApplicationMixin(this)
    useControllerMixin(this)
    this.storeControllerInElement()
  }

  increaseQuantity(event) {
    event.preventDefault()

    const parent = event.currentTarget.parentElement
    const label = parent.querySelector(this.selectors.quantityLabel)
    const input = parent.querySelector(this.selectors.quantityInput)
    let newValue = parseInt(input.value) + 1

    if (event.currentTarget.dataset.divisiblePackaging == 'false' ) {
      newValue = parseInt(input.value) + parseInt(event.currentTarget.dataset.packagingQuantity)
    }

    input.value = newValue
    if (label) label.innerHTML = newValue

    if (event.currentTarget.dataset.populate == 'true') {
      this.updateReservation(event)
    }
  }

  decreaseQuantity(event) {
    event.preventDefault()

    const parent = event.currentTarget.parentElement
    const label = parent.querySelector(this.selectors.quantityLabel)
    const input = parent.querySelector(this.selectors.quantityInput)
    const minValue = input.dataset.minValue
    let newValue = parseInt(input.value) - 1

    if (event.currentTarget.dataset.divisiblePackaging == 'false' ) {
      newValue = parseInt(input.value) - parseInt(event.currentTarget.dataset.packagingQuantity)
    }

    if (newValue < minValue) {
      newValue = minValue
    }

    input.value = newValue
    if (label) label.innerHTML = newValue

    if (event.currentTarget.dataset.populate == 'true') {
      this.updateReservation(event)
    }
  }

  destroy(event) {
    event.preventDefault()

    event.currentTarget.parentNode.querySelector(this.selectors.reservationItemDestruction).value = 1
    this.updateReservation(event)
  }

  updateReservation(event) {
    event.preventDefault()

    const form = event.currentTarget.closest('form')

    form.action = `${form.dataset.source}`

    if (event.currentTarget.dataset.checkout == 'true') {
      form.dataset.remote = 'false'
      form.dataset.type = 'html'
      form.action = `${form.action}${form.action.includes('?') ? '&' : '?'}checkout`
    }

    if (event.currentTarget.dataset.name == 'quantity-mover') {
      form.action = `${form.action}${form.action.includes('?') ? '&' : '?'}quantity_mover=true`
    }

    if (event.currentTarget.dataset.confirmReservation == 'true') {
      form.action = `${form.action}${form.action.includes('?') ? '&' : '?'}confirm=true`
    }

    if (form.dataset.remote == 'true') {
      Rails.fire(form, 'submit')
    } else {
      form.submit()
    }
  }

  onReservationUpdate(event) {
    let [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)

    const formHtml = response.form_html
    const successfullyCompleted = response.successfuly_completed

    if (successfullyCompleted) {
      this.getController('reservations--modal').close(event)
      this.getController('modal').set(response.confirmation_html)
      this.getController('reservations--modal').updateCounters(0)
    } else {
      this.getController('reservations--modal').updateCounters(response.items_count)
    }

    event.currentTarget.innerHTML = formHtml
  }
}
