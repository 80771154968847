class GlobalUtil {
  forEach(array, callback, scope) {
    if (array) {
      for (let i = 0; i < array.length; i++) {
        callback.call(scope, array[i], i)
      }
    }
  }

  getLocale() {
    const lang = document.querySelector('html').getAttribute('lang')

    return lang || 'fr'
  }

  isVisible(el) {
    return el => !!el && !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length)
  }

  viewportOffset(el) {
    const rect = el.getBoundingClientRect()
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  wrap(el, wrapper = document.createElement('div')) {
    el.parentNode.insertBefore(wrapper, el)
    wrapper.appendChild(el)
  }

  insertAfter(el, reference) {
    reference.parentNode.insertBefore(el, reference)
    reference.parentNode.insertBefore(reference, el)
  }
}

const instance = new GlobalUtil()
Object.freeze(instance)

export default instance
