import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm'
import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.selectors = {
      container: '[data-controller="' + this.element.dataset.controller +'"]',
    }
  }

  connect() {
    if (document.querySelector(this.selectors.container)) {
      new Glide(this.selectors.container).mount({ Controls })
    }
  }
}
