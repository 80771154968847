const plugin = require('tailwindcss/plugin')

module.exports = plugin(function({ addUtilities, variants }) {
  const newUtility = {
    '.flex-center': {
      alignItems: 'center',
      justifyContent: 'center',
    },
  }

  addUtilities(newUtility, variants('justifyContent'))
})
