import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['results']

  handleKeyup(event) {
    this.getResults(event.currentTarget)
  }

  handleFocusIn(event) {
    this.getResults(event.currentTarget)
  }

  handleFocusOut(event) {
    setTimeout(() => {
      if (!this.element.contains(document.activeElement)) {
        this.hideResults()
      }
    }, 100)
  }

  getResults(input) {
    if (input.value.length >= 2) {
      const url = input.dataset.autocompleteSource + '?search=' + input.value

      Rails.ajax({
        url: url,
        type: 'GET',
        dataType: 'html',
        success: (html) => {
          if (this.hasResultsTarget) {
            if (html.body.hasChildNodes()) {
              this.resultsTarget.innerHTML = html.body.innerHTML
              this.resultsTarget.classList.remove('hidden')
            } else {
              this.hideResults()
            }
          }
        }
      })
    } else {
      this.hideResults()
    }
  }

  hideResults() {
    this.resultsTarget.innerHTML = ''
    this.resultsTarget.classList.add('hidden')
  }
}
